<template>
  <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
    <template v-slot:title>Base Price Profile</template>
    <template v-slot:content>
      <bs-error-message :errors="errorMessages"/>

      <div class="mb-3">
        <label for="timeRange" class="form-label">Profil</label>
        <base-profile-combo v-model="model.profilId" :text-default="model.profilNama" :disabled="true"/>
        <bs-input-error :errors="errorMessages" keyError="Base Price Profil"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Quantity</label>
        <bs-textbox type="number" v-model="model.quantity"/>
        <bs-input-error :errors="errorMessages" keyError="Quantity"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Remark</label>
        <bs-textbox v-model="model.remarks"/>
      </div>


      <div class="mb-3">
        <label for="code" class="form-label">Valid From</label>
        <bs-date-picker v-model="model.validityStart"/>
        <bs-input-error :errors="errorMessages" keyError="Validity Start"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Valid End</label>
        <bs-date-picker v-model="model.validityEnd"/>
        <bs-input-error :errors="errorMessages" keyError="Validity End"/>
      </div>

    </template>
    <template v-slot:footer>
      <button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
      <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
    </template>
  </bs-popup>
</template>

<script>
import BasePriceService from "@/services/base-price-service";
import BaseProfileCombo from "@/components/BasePriceProfileCombo.vue";


export default {
  components: {
    BaseProfileCombo
  },
  data: function () {
    return {
      id: 0,
      model: BasePriceService.defaultForEdit(),
      errorMessages: null
    }
  },
  mounted() {
    let id = this.$route.params.id;
    this.id = id;
    this.loadData();
  },
  methods: {
    async loadData() {
      const result = await BasePriceService.getObject(this.id);
      if (result.status) {
        this.errorMessages = result.errorMessages;
      } else {
        this.errorMessages = null;
      }

      this.model = result.data;
    },
    async onPopupClosed() {
      this.$router.push('/base-price');
    },
    async close() {
      this.$refs.form.closePopup();
    },
    async saveData() {
      try {
        this.$store.commit('progressCounterQueueIncrement');
        let status = false;
        // TODO logging tiap edit
        const result = await BasePriceService.update(this.model, this.id);
        status = result.status;
        if (status) {
          this.errorMessages = null;
        } else {
          this.errorMessages = result.errorMessages;
        }

        if (!status) return;

        this.$refs.form.closePopup();

        this.$router.push(`/base-price/detail/${this.id}`);


      } finally {
        this.$store.commit('progressCounterQueueDecrement');
      }
    }
  }
}
</script>