<template>
  <bs-select v-model="text" @onSelect="selectProfile" @onSearch="filterUnit" :options="list" :disabled="disabled">
    <template v-slot:default="props">
      <div>{{props.item.nama}}</div>
    </template>
  </bs-select>
</template>
<script>
import BasePriceProfileService from "@/services/base-price-profile-service";

export default {
  name: 'BaseProfileCombo',
  data: function() {
    return {
      text: '',
      list: null,
      hasFocus: false
    }
  },
  props: {
    modelValue: Number,
    textDefault: String,
    disabled:{
      type: Boolean,
      default: false
    }
  },
  updated() {
    if (!this.hasFocus)
    {
      this.hasFocus = true;
      this.setComboLabel(this.modelValue);
    }
  },
  emits: ['update:modelValue'],
  methods: {
    async setComboLabel(id) {
      if (id === undefined || id === null || id === '' || id < 1) {
        this.text = '';
        return;
      }

      const obj = await BasePriceProfileService.getObject(id);
      if (obj.status) {
        this.text = `${obj.data.nama}`;
      }
      else {
        this.text = '';
        alert(`Failed load profile with id = '${id}'.`);
      }
    },
    selectProfile(item) {
      if (item == null) {
        this.$emit('update:modelValue', null);
      }
      else
      {
        this.$emit('update:modelValue', item.id);
      }
      this.setComboLabel(item == null ? null : item.id);
    },
    async filterUnit(value) {
      const filter = {
        namaContains: value
      };

      const result = await BasePriceProfileService.getList(filter);
      let arrayResult = null;
      if (result.status) {
        if (result.data != null && result.data.data != null)
        {
          arrayResult = result.data.data;
        }
      }

      this.list = arrayResult;
    }
  }
}
</script>